import { memo, useCallback } from 'react';
import classNames from 'classnames';
import { Relations } from './Relations';
import css from './NodeDetails.module.css';

interface NodeDetailsProps {
  node: any;
  className?: string;
  onSelect: (nodeId: string | undefined) => void;
  onHover: (nodeId: string) => void;
  onClear: () => void;
}

export const NodeDetails = memo(function NodeDetails({
  node,
  className,
  ...props
}: NodeDetailsProps) {
  const closeHandler = useCallback(() => props.onSelect(undefined), [props]);

  return (
    <section className={classNames(css.root, className)}>
      <header className={css.header}>
        <h3 className={css.title}>{node?.name}</h3>
        <button className={css.close} onClick={closeHandler}>
          &#10005;
        </button>
      </header>
      <Relations {...props} title="Prinderit" items={node.parents} />
      <Relations {...props} title="Femijet" items={node.children} />
      <Relations {...props} title="Te Afermit" items={node.siblings} />
      <Relations {...props} title="Bashkshortja" items={node.spouses} />
    </section>
  );
});
