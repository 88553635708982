import { DummyData } from './DummyData';
import { FamilyTree } from './FamilyTree';
import { testData } from './testData';

export const NODE_WIDTH = 70;
export const NODE_HEIGHT = 80;

export const SOURCES = {
  'familytree.json': FamilyTree,
  'dummy.json': DummyData,
  'empty.json': testData,
} as Readonly<{ [key: string]: readonly Readonly<any>[] }>;

export const DEFAULT_SOURCE = Object.keys(SOURCES)[0];

export const URL_LABEL = 'URL (Gist, Paste.bin, ...)';
