export const FamilyTree = [
  {
    id: '1',
    gender: 'male',
    name: 'Sali',
    parents: [],
    children: [{ id: '2', type: 'I Biri' }],
    siblings: [],
    spouses: [],
  },
  {
    id: '2',
    gender: 'male',
    name: 'Ahmet',
    parents: [{ id: '1', type: 'I Biri' }],
    children: [{ id: '3', type: 'I Biri' }],
    siblings: [],
    spouses: [],
  },
  {
    id: '3',
    gender: 'male',
    name: 'Nezir',
    parents: [{ id: '2', type: 'I Biri' }],
    children: [
      { id: '4', type: ' I Biri' },
      { id: '6', type: ' I Biri' },
      { id: '7', type: ' I Biri' },
      { id: '9', type: ' I Biri' },
    ],
    siblings: [],
    spouses: [],
  },
  {
    id: '4',
    gender: 'male',
    name: 'Isuf',
    parents: [{ id: '3', type: 'I Biri' }],
    children: [
      {
        id: '10',
        type: 'I Biri',
      },
      {
        id: '11',
        type: 'I Biri',
      },
      {
        id: '12',
        type: 'I Biri',
      },
      {
        id: '13',
        type: 'I Biri',
      },
    ],
    siblings: [],
    spouses: [
      {
        id: '5',
        type: 'Bashkshortja',
      },
    ],
  },
  {
    id: '5',
    gender: 'female',
    name: 'Fatime',
    parents: [],
    children: [
      {
        id: '10',
        type: 'I Biri',
      },
      {
        id: '11',
        type: 'I Biri',
      },
      {
        id: '12',
        type: 'I Biri',
      },
      {
        id: '13',
        type: 'I Biri',
      },
    ],
    siblings: [],
    spouses: [],
  },
  {
    id: '6',
    gender: 'male',
    name: 'Dali',
    parents: [{ id: '3', type: 'I Biri' }],
    children: [
      { id: '15', type: ' I Biri' },
      { id: '16', type: ' I Biri' },
      { id: '17', type: ' I Bija' },
      { id: '18', type: ' I Bija' },
      { id: '21', type: ' I Bija' },
    ],
    siblings: [],
    spouses: [],
  },
  {
    id: '7',
    gender: 'male',
    name: 'Sule',
    parents: [{ id: '3', type: 'I Biri' }],
    children: [
      { id: '26', type: 'I Biri' },
      { id: '27', type: 'I Biri' },
      { id: '28', type: 'E Bija' },
      { id: '29', type: 'E Bija' },
      { id: '30', type: 'E Bija' },
    ],
    siblings: [],
    spouses: [
      {
        id: '8',
        type: 'Bashkshortja',
      },
    ],
  },
  {
    id: '8',
    gender: 'female',
    name: 'Vashe',
    parents: [],
    children: [
      { id: '26', type: 'I Biri' },
      { id: '27', type: 'I Biri' },
      { id: '28', type: 'E Bija' },
      { id: '29', type: 'E Bija' },
      { id: '30', type: 'E Bija' },
    ],
    siblings: [],
    spouses: [],
  },
  {
    id: '9',
    gender: 'male',
    name: 'Bajram',
    parents: [{ id: '3', type: 'I Biri' }],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '10',
    gender: 'male',
    name: 'Sali',
    parents: [{ id: '3', type: 'I Biri' }],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '9',
    gender: 'male',
    name: 'Imer',
    parents: [{ id: '3', type: 'I Biri' }],
    children: [
      { id: '33', type: 'I Biri' },
      { id: '34', type: 'I Biri' },
      { id: '35', type: 'I Biri' },
      { id: '36', type: 'I Biri' },
      { id: '37', type: 'E Bija' },
      { id: '38', type: 'E Bija' },
      { id: '39', type: 'E Bija' },
    ],
    siblings: [],
    spouses: [{ id: '14', type: 'Bashkshortja' }],
  },
  {
    id: '14',
    gender: 'female',
    name: 'Sulltana',
    parents: [],
    children: [
      { id: '33', type: 'I Biri' },
      { id: '34', type: 'I Biri' },
      { id: '35', type: 'I Biri' },
      { id: '36', type: 'I Biri' },
      { id: '37', type: 'E Bija' },
      { id: '38', type: 'E Bija' },
      { id: '39', type: 'E Bija' },
    ],
    siblings: [],
    spouses: [],
  },
  {
    id: '10',
    gender: 'male',
    name: 'Aliu',
    parents: [
      { id: '4', type: 'I Biri' },
      { id: '5', type: 'I Biri' },
    ],
    children: [
      { id: '43', type: ' I Biri' },
      { id: '44', type: 'I Bija' },
      { id: '45', type: 'I Biri' },
      { id: '46', type: 'E Bija' },
    ],
    siblings: [],
    spouses: [{ id: '22', type: ' Bashkshortja' }],
  },
  {
    id: '11',
    gender: 'male',
    name: 'Sehit',
    parents: [
      { id: '4', type: 'I Biri' },
      { id: '5', type: 'I Biri' },
    ],
    children: [
      { id: '49', type: 'I Biri' },
      { id: '50', type: ' I Biri' },
      { id: '51', type: 'E Bija' },
    ],
    siblings: [],
    spouses: [{ id: '23', type: 'Bashkshortja' }],
  },
  {
    id: '12',
    gender: 'male',
    name: 'Qazimi',
    parents: [
      { id: '4', type: 'I Biri' },
      { id: '5', type: 'I Biri' },
    ],
    children: [
      { id: '52', type: ' I Biri' },
      { id: '53', type: 'E Bija' },
      { id: '54', type: 'E Bija' },
      { id: '55', type: 'I Biri' },
    ],
    siblings: [],
    spouses: [{ id: '24', type: 'Bashkshortja' }],
  },
  {
    id: '13',
    gender: 'male',
    name: 'Haziz',
    parents: [
      { id: '4', type: 'Babai' },
      { id: '5', type: 'E Ema' },
    ],
    children: [
      { id: '56', type: 'E Bija' },
      { id: '57', type: 'I Biri' },
      { id: '58', type: 'E Bija' },
      { id: '59', type: 'E Bija' },
      { id: '60', type: 'E Bija' },
      { id: '61', type: 'I Biri' },
    ],
    siblings: [],
    spouses: [{ id: '25', type: 'Bashkshortja' }],
  },
  {
    id: '15',
    gender: 'male',
    name: 'Bilali',
    parents: [{ id: '6', type: 'I Biri' }],
    children: [
      { id: '67', type: 'E Bija' },
      { id: '68', type: 'I Biri' },
      { id: '69', type: 'I Biri' },
      { id: '70', type: 'I Biri' },
      { id: '71', type: 'E Bija' },
    ],
    siblings: [],
    spouses: [{ id: '19', type: 'Bashkshortja' }],
  },
  {
    id: '16',
    gender: 'male',
    name: 'Islam',
    parents: [{ id: '6', type: 'I Biri' }],
    children: [
      { id: '75', type: 'I Biri' },
      { id: '76', type: 'I Biri' },
      { id: '77', type: 'E Bija' },
      { id: '78', type: 'I Biri' },
      { id: '79', type: 'E Bija' },
      { id: '80', type: 'I Biri' },
      { id: '81', type: 'E Bija' },
      { id: '82', type: 'E Bija' },
      { id: '83', type: 'I Biri' },
    ],
    siblings: [],
    spouses: [{ id: '20', type: 'Bashkshortja' }],
  },
  {
    id: '17',
    gender: 'female',
    name: 'Selvije',
    parents: [{ id: '6', type: 'I Biri' }],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '18',
    gender: 'female',
    name: 'Mine',
    parents: [{ id: '6', type: 'I Biri' }],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '21',
    gender: 'female',
    name: 'Zelije',
    parents: [{ id: '6', type: 'I Biri' }],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '19',
    gender: 'female',
    name: 'Xharije',
    parents: [],
    children: [
      { id: '67', type: 'E Bija' },
      { id: '68', type: 'I Biri' },
      { id: '69', type: 'I Biri' },
      { id: '70', type: 'I Biri' },
      { id: '71', type: 'E Bija' },
    ],
    siblings: [],
    spouses: [],
  },
  {
    id: '20',
    gender: 'female',
    name: 'Sanije',
    parents: [],
    children: [
      { id: '75', type: 'I Biri' },
      { id: '76', type: 'I Biri' },
      { id: '77', type: 'E Bija' },
      { id: '78', type: 'I Biri' },
      { id: '79', type: 'E Bija' },
      { id: '80', type: 'I Biri' },
      { id: '81', type: 'E Bija' },
      { id: '82', type: 'E Bija' },
      { id: '83', type: 'I Biri' },
    ],
    siblings: [],
    spouses: [],
  },
  {
    id: '22',
    gender: 'female',
    name: 'Peme',
    parents: [],
    children: [
      { id: '43', type: ' I Biri' },
      { id: '44', type: 'I Bija' },
      { id: '45', type: 'I Biri' },
      { id: '46', type: 'E Bija' },
    ],
    siblings: [],
    spouses: [],
  },
  {
    id: '23',
    gender: 'female',
    name: 'Naze',
    parents: [],
    children: [
      { id: '49', type: 'I Biri' },
      { id: '50', type: ' I Biri' },
      { id: '51', type: 'E Bija' },
    ],
    siblings: [],
    spouses: [],
  },
  {
    id: '24',
    gender: 'female',
    name: 'Korbe',
    parents: [],
    children: [
      { id: '52', type: ' I Biri' },
      { id: '53', type: 'E Bija' },
      { id: '54', type: 'E Bija' },
      { id: '55', type: 'I Biri' },
    ],
    siblings: [],
    spouses: [],
  },
  {
    id: '25',
    gender: 'female',
    name: 'Zamane',
    parents: [],
    children: [
      { id: '56', type: 'E Bija' },
      { id: '57', type: 'I Biri' },
      { id: '58', type: 'E Bija' },
      { id: '59', type: 'E Bija' },
      { id: '60', type: 'E Bija' },
      { id: '61', type: 'I Biri' },
    ],
    siblings: [],
    spouses: [],
  },
  {
    id: '26',
    gender: 'male',
    name: 'Kapllani',
    parents: [
      { id: '7', type: 'Babai' },
      { id: '8', type: 'E Ema' },
    ],
    children: [
      { id: '88', type: 'I Biri' },
      { id: '89', type: 'E Bija' },
    ],
    siblings: [],
    spouses: [{ id: '31', type: 'Bashkshortja' }],
  },
  {
    id: '27',
    gender: 'male',
    name: 'Haxhi',
    parents: [
      { id: '7', type: 'Babai' },
      { id: '8', type: 'E Ema' },
    ],
    children: [
      { id: '91', type: 'I Biri' },
      { id: '92', type: 'E Bija' },
      { id: '93', type: 'E Bija' },
      { id: '94', type: 'I Biri' },
      { id: '95', type: 'E Bija' },
      { id: '96', type: 'I Biri' },
      { id: '97', type: 'E Bija' },
      { id: '98', type: 'E Bija' },
    ],
    siblings: [],
    spouses: [{ id: '32', type: 'Bashkshortja' }],
  },
  {
    id: '28',
    gender: 'female',
    name: 'Naze',
    parents: [
      { id: '7', type: 'Babai' },
      { id: '8', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '29',
    gender: 'female',
    name: 'Dave',
    parents: [
      { id: '7', type: 'Babai' },
      { id: '8', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '30',
    gender: 'female',
    name: 'Kade',
    parents: [
      { id: '7', type: 'Babai' },
      { id: '8', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '31',
    gender: 'female',
    name: 'Lutfije',
    parents: [],
    children: [
      { id: '88', type: 'I Biri' },
      { id: '89', type: 'E Bija' },
    ],
    siblings: [],
    spouses: [],
  },
  {
    id: '32',
    gender: 'female',
    name: 'Vashe',
    parents: [],
    children: [
      { id: '91', type: 'I Biri' },
      { id: '92', type: 'E Bija' },
      { id: '93', type: 'E Bija' },
      { id: '94', type: 'I Biri' },
      { id: '95', type: 'E Bija' },
      { id: '96', type: 'I Biri' },
      { id: '97', type: 'E Bija' },
      { id: '98', type: 'E Bija' },
    ],
    siblings: [],
    spouses: [],
  },
  {
    id: '33',
    gender: 'male',
    name: 'Cupo',
    parents: [
      { id: '8', type: 'Babai' },
      { id: '14', type: 'E Ema' },
    ],
    children: [
      { id: '99', type: 'I Biri' },
      { id: '100', type: 'I Biri' },
      { id: '101', type: 'E Bija' },
    ],
    siblings: [],
    spouses: [{ id: '40', type: 'Bashkshortja' }],
  },
  {
    id: '34',
    gender: 'male',
    name: 'Halil',
    parents: [
      { id: '8', type: 'Babai' },
      { id: '14', type: 'E Ema' },
    ],
    children: [
      { id: '104' },
      { id: '105' },
      { id: '106' },
      { id: '107' },
      { id: '108' },
      { id: '109' },
      { id: '110' },
    ],
    siblings: [],
    spouses: [{ id: '41', type: 'Bashkshortja' }],
  },
  {
    id: '35',
    gender: 'male',
    name: 'Shahin',
    parents: [
      { id: '8', type: 'Babai' },
      { id: '14', type: 'E Ema' },
    ],
    children: [
      { id: '114', type: 'I Biri' },
      { id: '115', type: 'E Bija' },
      { id: '116', type: 'I Biri' },
      { id: '117', type: 'E Bija' },
      { id: '118', type: 'I Biri' },
      { id: '119', type: 'I Biri' },
    ],
    siblings: [],
    spouses: [{ id: '42', type: 'Bashkshortja' }],
  },
  {
    id: '36',
    gender: 'male',
    name: 'Myslim',
    parents: [
      { id: '8', type: 'Babai' },
      { id: '14', type: 'E Ema' },
    ],
    children: [
      { id: '125', type: 'I Biri' },
      { id: '126', type: 'E Bija' },
    ],
    siblings: [],
    spouses: [{ id: '124', type: 'Bashkshortja' }],
  },
  {
    id: '37',
    gender: 'female',
    name: 'Dushe',
    parents: [
      { id: '8', type: 'Babai' },
      { id: '14', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '38',
    gender: 'female',
    name: 'Hamite',
    parents: [
      { id: '8', type: 'Babai' },
      { id: '14', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '39',
    gender: 'female',
    name: 'Hike',
    parents: [
      { id: '8', type: 'Babai' },
      { id: '14', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '40',
    gender: 'female',
    name: 'Mile',
    parents: [],
    children: [
      { id: '99', type: 'I Biri' },
      { id: '100', type: 'I Biri' },
      { id: '101', type: 'E Bija' },
    ],
    siblings: [],
    spouses: [],
  },
  {
    id: '41',
    gender: 'female',
    name: 'Vashe',
    parents: [],
    children: [
      { id: '104' },
      { id: '105' },
      { id: '106' },
      { id: '107' },
      { id: '108' },
      { id: '109' },
      { id: '110' },
    ],
    siblings: [],
    spouses: [],
  },
  {
    id: '42',
    gender: 'female',
    name: 'Mire',
    parents: [],
    children: [
      { id: '114', type: 'I Biri' },
      { id: '115', type: 'E Bija' },
      { id: '116', type: 'I Biri' },
      { id: '117', type: 'E Bija' },
      { id: '118', type: 'I Biri' },
      { id: '119', type: 'I Biri' },
    ],
    siblings: [],
    spouses: [],
  },
  {
    id: '43',
    gender: 'male',
    name: 'Hasan',
    parents: [
      { id: '10', type: 'Babai' },
      { id: '22', type: 'E Ema' },
    ],
    children: [
      { id: '128', type: 'I Biri' },
      { id: '129', type: 'E Bija' },
      { id: '130', type: 'I Biri' },
      { id: '131', type: 'I Biri' },
      { id: '132', type: 'I Biri' },
    ],
    siblings: [],
    spouses: [{ id: '47', type: 'Bashkshortja' }],
  },
  {
    id: '44',
    gender: 'female',
    name: 'Jeme',
    parents: [
      { id: '10', type: 'Babai' },
      { id: '22', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '45',
    gender: 'male',
    name: 'Isuf',
    parents: [
      { id: '10', type: 'Babai' },
      { id: '22', type: 'E Ema' },
    ],
    children: [
      { id: '137', type: 'I Biri' },
      { id: '138', type: 'E Bija' },
      { id: '139', type: 'I Biri' },
      { id: '140', type: 'I Biri' },
      { id: '141', type: 'I Biri' },
    ],
    siblings: [],
    spouses: [{ id: '48', type: 'Bashkshortja' }],
  },
  {
    id: '46',
    gender: 'female',
    name: 'Beklije',
    parents: [
      { id: '10', type: 'Babai' },
      { id: '22', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '47',
    gender: 'female',
    name: 'Bede',
    parents: [],
    children: [
      { id: '128', type: 'I Biri' },
      { id: '129', type: 'E Bija' },
      { id: '130', type: 'I Biri' },
      { id: '131', type: 'I Biri' },
      { id: '132', type: 'I Biri' },
    ],
    siblings: [],
    spouses: [],
  },
  {
    id: '48',
    gender: 'female',
    name: 'Shkurta',
    parents: [],
    children: [
      { id: '137', type: 'I Biri' },
      { id: '138', type: 'E Bija' },
      { id: '139', type: 'I Biri' },
      { id: '140', type: 'I Biri' },
      { id: '141', type: 'I Biri' },
    ],
    siblings: [],
    spouses: [],
  },
  {
    id: '49',
    gender: 'male',
    name: 'Shaqir',
    parents: [
      { id: '11', type: 'Babai' },
      { id: '23', type: 'E Ema' },
    ],
    children: [
      { id: '142', type: 'I Biri' },
      { id: '143', type: 'I Biri' },
      { id: '144', type: 'I Biri' },
      { id: '145', type: 'I Biri' },
      { id: '146', type: 'I Biri' },
      { id: '147', type: 'E Bija' },
      { id: '148', type: 'E Bija' },
      { id: '149', type: 'I Biri' },
      { id: '150', type: 'I Biri' },
    ],
    siblings: [],
    spouses: [{ id: '62', type: 'Bashkshorte' }],
  },
  {
    id: '50',
    gender: 'male',
    name: 'Hamit',
    parents: [
      { id: '11', type: 'Babai' },
      { id: '23', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [{ id: '63', type: 'Bashkshorte' }],
  },
  {
    id: '51',
    gender: 'female',
    name: 'Lihje',
    parents: [
      { id: '11', type: 'Babai' },
      { id: '23', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '52',
    gender: 'male',
    name: 'Xhelal',
    parents: [
      { id: '12', type: 'Babai' },
      { id: '24', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [{ id: '64', type: 'Bashkshorte' }],
  },
  {
    id: '53',
    gender: 'female',
    name: 'Sulbija',
    parents: [
      { id: '12', type: 'Babai' },
      { id: '24', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '54',
    gender: 'female',
    name: 'Xharija',
    parents: [
      { id: '12', type: 'Babai' },
      { id: '24', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '55',
    gender: 'male',
    name: 'Gani',
    parents: [
      { id: '12', type: 'Babai' },
      { id: '24', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [{ id: '65', type: 'Bashkshortja' }],
  },
  {
    id: '56',
    gender: 'female',
    name: 'Bukurija',
    parents: [
      { id: '13', type: 'Babai' },
      { id: '25', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '57',
    gender: 'male',
    name: 'Bedri',
    parents: [
      { id: '13', type: 'Babai' },
      { id: '25', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [{ id: '66', type: 'Bashkshortja' }],
  },
  {
    id: '58',
    gender: 'female',
    name: 'Fatime',
    parents: [
      { id: '13', type: 'Babai' },
      { id: '25', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '59',
    gender: 'female',
    name: 'Fiqirete',
    parents: [
      { id: '13', type: 'Babai' },
      { id: '25', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '60',
    gender: 'female',
    name: 'Xhevrije',
    parents: [
      { id: '13', type: 'Babai' },
      { id: '25', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '61',
    gender: 'male',
    name: 'Besim',
    parents: [
      { id: '13', type: 'Babai' },
      { id: '25', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '62',
    gender: 'female',
    name: 'Vazyle',
    parents: [],
    children: [
      { id: '142', type: 'I Biri' },
      { id: '143', type: 'I Biri' },
      { id: '144', type: 'I Biri' },
      { id: '145', type: 'I Biri' },
      { id: '146', type: 'I Biri' },
      { id: '147', type: 'E Bija' },
      { id: '148', type: 'E Bija' },
      { id: '149', type: 'I Biri' },
      { id: '150', type: 'I Biri' },
    ],
    siblings: [],
    spouses: [],
  },
  {
    id: '63',
    gender: 'female',
    name: 'Suzana',
    parents: [],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '64',
    gender: 'female',
    name: 'Hidije',
    parents: [],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '65',
    gender: 'female',
    name: 'Natasha',
    parents: [],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '66',
    gender: 'female',
    name: 'Mira',
    parents: [],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '67',
    gender: 'female',
    name: 'Vera',
    parents: [
      { id: '15', type: 'Babai' },
      { id: '19', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '68',
    gender: 'male',
    name: 'Dali',
    parents: [
      { id: '15', type: 'Babai' },
      { id: '19', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [{ id: '72', type: 'Bashkshortja' }],
  },
  {
    id: '69',
    gender: 'male',
    name: 'Ahmet',
    parents: [
      { id: '15', type: 'Babai' },
      { id: '19', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [{ id: '73', type: 'Bashkshortja' }],
  },
  {
    id: '70',
    gender: 'male',
    name: 'Rufat',
    parents: [
      { id: '15', type: 'Babai' },
      { id: '19', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [{ id: '74', type: 'Bashkshortja' }],
  },
  {
    id: '71',
    gender: 'female',
    name: 'Behixhe',
    parents: [
      { id: '15', type: 'Babai' },
      { id: '19', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '72',
    gender: 'female',
    name: 'Valentina',
    parents: [],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '73',
    gender: 'female',
    name: 'Nurije',
    parents: [],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '74',
    gender: 'female',
    name: 'Shqipe',
    parents: [],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '75',
    gender: 'male',
    name: 'Skender',
    parents: [
      { id: '16', type: 'Babai' },
      { id: '20', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [{ id: '84', type: 'Bashkshortja' }],
  },
  {
    id: '76',
    gender: 'male',
    name: 'Agim',
    parents: [
      { id: '16', type: 'Babai' },
      { id: '20', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [{ id: '85', type: 'Bashkshorte' }],
  },
  {
    id: '77',
    gender: 'female',
    name: 'Drita',
    parents: [
      { id: '16', type: 'Babai' },
      { id: '20', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '78',
    gender: 'male',
    name: 'Tomorr',
    parents: [
      { id: '16', type: 'Babai' },
      { id: '20', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [{ id: '86', type: 'Bashkshorte' }],
  },
  {
    id: '79',
    gender: 'female',
    name: 'Merita',
    parents: [
      { id: '16', type: 'Babai' },
      { id: '20', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '80',
    gender: 'male',
    name: 'Lefter',
    parents: [
      { id: '16', type: 'Babai' },
      { id: '20', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '81',
    gender: 'female',
    name: 'Shpresa',
    parents: [
      { id: '16', type: 'Babai' },
      { id: '20', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '82',
    gender: 'female',
    name: 'Jeta',
    parents: [
      { id: '16', type: 'Babai' },
      { id: '20', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '83',
    gender: 'male',
    name: 'Aranit',
    parents: [
      { id: '16', type: 'Babai' },
      { id: '20', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [{ id: '87', type: 'Bashkshorte' }],
  },
  {
    id: '84',
    gender: 'female',
    name: 'Fatime',
    parents: [],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '85',
    gender: 'female',
    name: 'Thellenxa',
    parents: [],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '86',
    gender: 'female',
    name: 'Mimoza',
    parents: [],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '87',
    gender: 'female',
    name: 'Adelina',
    parents: [],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '88',
    gender: 'male',
    name: 'Veli',
    parents: [
      { id: '26', type: 'Babai' },
      { id: '31', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [{ id: '90', type: 'Bashkshorte' }],
  },
  {
    id: '89',
    gender: 'female',
    name: 'Lule',
    parents: [
      { id: '26', type: 'Babai' },
      { id: '31', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '90',
    gender: 'female',
    name: 'Zebede',
    parents: [],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '91',
    gender: 'male',
    name: 'Shkelqim',
    parents: [
      { id: '27', type: 'Babai' },
      { id: '32', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '92',
    gender: 'female',
    name: 'Vjollca',
    parents: [
      { id: '27', type: 'Babai' },
      { id: '32', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '93',
    gender: 'female',
    name: 'Flora',
    parents: [
      { id: '27', type: 'Babai' },
      { id: '32', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '94',
    gender: 'male',
    name: 'Bujar',
    parents: [
      { id: '27', type: 'Babai' },
      { id: '32', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '95',
    gender: 'female',
    name: 'Arta',
    parents: [
      { id: '27', type: 'Babai' },
      { id: '32', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '96',
    gender: 'male',
    name: 'Robert',
    parents: [
      { id: '27', type: 'Babai' },
      { id: '32', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '97',
    gender: 'female',
    name: 'Dorina',
    parents: [
      { id: '27', type: 'Babai' },
      { id: '32', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '98',
    gender: 'female',
    name: 'Mimoza',
    parents: [
      { id: '27', type: 'Babai' },
      { id: '32', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '99',
    gender: 'male',
    name: 'Xhemal',
    parents: [
      { id: '33', type: 'Babai' },
      { id: '40', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [{ id: '102', type: 'Bashkshorte' }],
  },
  {
    id: '100',
    gender: 'male',
    name: 'Haqif',
    parents: [
      { id: '33', type: 'Babai' },
      { id: '40', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [{ id: '103', type: 'Bashkshorte' }],
  },
  {
    id: '101',
    gender: 'female',
    name: 'Nafije',
    parents: [
      { id: '33', type: 'Babai' },
      { id: '40', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '102',
    gender: 'female',
    name: 'Sarije',
    parents: [],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '103',
    gender: 'female',
    name: 'Kumrije',
    parents: [],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '104',
    gender: 'male',
    name: 'Qamil',
    parents: [
      { id: '34', type: 'Babai' },
      { id: '41', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [{ id: '111', type: 'Bashkshortja' }],
  },
  {
    id: '105',
    gender: 'male',
    name: 'Ymer',
    parents: [
      { id: '34', type: 'Babai' },
      { id: '41', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [{ id: '112', type: 'Bashkshortja' }],
  },
  {
    id: '106',
    gender: 'female',
    name: 'Lute',
    parents: [
      { id: '34', type: 'Babai' },
      { id: '41', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '107',
    gender: 'female',
    name: 'Shkurte',
    parents: [
      { id: '34', type: 'Babai' },
      { id: '41', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '108',
    gender: 'male',
    name: 'Hysni',
    parents: [
      { id: '34', type: 'Babai' },
      { id: '41', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [{ id: '113', type: 'Bashkshortja' }],
  },
  {
    id: '109',
    gender: 'female',
    name: 'Xhevahire',
    parents: [
      { id: '34', type: 'Babai' },
      { id: '41', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '110',
    gender: 'female',
    name: 'Hanke',
    parents: [
      { id: '34', type: 'Babai' },
      { id: '41', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '111',
    gender: 'female',
    name: 'Fiqirete',
    parents: [],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '112',
    gender: 'female',
    name: 'Hajrie',
    parents: [],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '113',
    gender: 'female',
    name: 'Dife',
    parents: [],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '114',
    gender: 'male',
    name: 'Ylber',
    parents: [
      { id: '35', type: 'Babai' },
      { id: '42', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [{ id: '120', type: 'Bashkshortja' }],
  },
  {
    id: '115',
    gender: 'female',
    name: 'Lirije',
    parents: [
      { id: '35', type: 'Babai' },
      { id: '42', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '116',
    gender: 'male',
    name: 'Kujtim',
    parents: [
      { id: '35', type: 'Babai' },
      { id: '42', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [{ id: '121', type: 'Bashkshortja' }],
  },
  {
    id: '117',
    gender: 'female',
    name: 'Nazmie',
    parents: [
      { id: '35', type: 'Babai' },
      { id: '42', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '118',
    gender: 'male',
    name: 'Ylli',
    parents: [
      { id: '35', type: 'Babai' },
      { id: '42', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [{ id: '122', type: 'Bashkshortja' }],
  },
  {
    id: '119',
    gender: 'male',
    name: 'Flamur',
    parents: [
      { id: '35', type: 'Babai' },
      { id: '42', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [{ id: '123', type: 'Bashkshortja' }],
  },
  {
    id: '120',
    gender: 'female',
    name: 'Lirije',
    parents: [],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '121',
    gender: 'female',
    name: 'Shpetime',
    parents: [],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '122',
    gender: 'female',
    name: 'Fitore',
    parents: [],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '123',
    gender: 'female',
    name: 'Sedie',
    parents: [],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '124',
    gender: 'female',
    name: 'Bardhe',
    parents: [],
    children: [
      { id: '125', type: 'I Biri' },
      { id: '126', type: 'E Bija' },
    ],
    siblings: [],
    spouses: [],
  },
  {
    id: '125',
    gender: 'male',
    name: 'Jashar',
    parents: [
      { id: '36', type: 'Babai' },
      { id: '124', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [{ id: '127', type: 'Bashkshortja' }],
  },
  {
    id: '126',
    gender: 'female',
    name: 'Sanije',
    parents: [
      { id: '36', type: 'Babai' },
      { id: '124', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '127',
    gender: 'female',
    name: 'Xharije',
    parents: [],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '128',
    gender: 'male',
    name: 'Ramazan',
    parents: [
      { id: '43', type: 'Babai' },
      { id: '47', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [{ id: '133', type: 'Bashkshortja' }],
  },
  {
    id: '129',
    gender: 'female',
    name: 'Sulltana',
    parents: [
      { id: '43', type: 'Babai' },
      { id: '47', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '130',
    gender: 'male',
    name: 'Kadri',
    parents: [
      { id: '43', type: 'Babai' },
      { id: '47', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [{ id: '134', type: 'Bashkshortja' }],
  },
  {
    id: '131',
    gender: 'male',
    name: 'Eduart',
    parents: [
      { id: '43', type: 'Babai' },
      { id: '47', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [{ id: '135', type: 'Bashkshortja' }],
  },
  {
    id: '132',
    gender: 'male',
    name: 'Astrit',
    parents: [
      { id: '43', type: 'Babai' },
      { id: '47', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [{ id: '136', type: 'Bashkshortja' }],
  },
  {
    id: '133',
    gender: 'female',
    name: 'Minushe',
    parents: [],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '134',
    gender: 'female',
    name: 'Vali',
    parents: [],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '135',
    gender: 'female',
    name: 'Mihane',
    parents: [],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '136',
    gender: 'female',
    name: 'Merita',
    parents: [],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '137',
    gender: 'male',
    name: 'Artan',
    parents: [
      { id: '45', type: 'Babai' },
      { id: '48', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '138',
    gender: 'female',
    name: 'Teuta',
    parents: [
      { id: '45', type: 'Babai' },
      { id: '48', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '139',
    gender: 'male',
    name: 'Elton',
    parents: [
      { id: '45', type: 'Babai' },
      { id: '48', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '140',
    gender: 'male',
    name: 'Brajan',
    parents: [
      { id: '45', type: 'Babai' },
      { id: '48', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '141',
    gender: 'male',
    name: 'Gentian',
    parents: [
      { id: '45', type: 'Babai' },
      { id: '48', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '142',
    gender: 'male',
    name: 'Ilmi',
    parents: [
      { id: '49', type: 'Babai' },
      { id: '62', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '143',
    gender: 'male',
    name: 'Bashkim',
    parents: [
      { id: '49', type: 'Babai' },
      { id: '62', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '144',
    gender: 'male',
    name: 'Petrit',
    parents: [
      { id: '49', type: 'Babai' },
      { id: '62', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '145',
    gender: 'male',
    name: 'Esat',
    parents: [
      { id: '49', type: 'Babai' },
      { id: '62', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '146',
    gender: 'male',
    name: 'Shpetim',
    parents: [
      { id: '49', type: 'Babai' },
      { id: '62', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '147',
    gender: 'female',
    name: 'Lihe',
    parents: [
      { id: '49', type: 'Babai' },
      { id: '62', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '148',
    gender: 'female',
    name: 'Naze',
    parents: [
      { id: '49', type: 'Babai' },
      { id: '62', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '149',
    gender: 'male',
    name: 'Sehit',
    parents: [
      { id: '49', type: 'Babai' },
      { id: '62', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '150',
    gender: 'male',
    name: 'Fatos',
    parents: [
      { id: '49', type: 'Babai' },
      { id: '62', type: 'E Ema' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
];
