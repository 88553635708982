export const DummyData = [
  {
    id: '1',
    gender: 'male',
    name: 'Gani',
    parents: [
      {
        id: '4',
        type: 'blood',
      },
      {
        id: '5',
        type: 'blood',
      },
    ],
    children: [
      {
        id: '3',
        type: 'blood',
      },
      {
        id: '8',
        type: 'blood',
      },
    ],
    siblings: [],
    spouses: [
      {
        id: '2',
        type: 'married',
      },
    ],
  },
  {
    id: '2',
    gender: 'female',
    name: 'Natasha',
    parents: [],
    children: [
      {
        id: '3',
        type: 'blood',
      },
      {
        id: '8',
        type: 'blood',
      },
    ],
    siblings: [],
    spouses: [],
  },
  {
    id: '6',
    gender: 'male',
    name: 'Xhelal',
    parents: [
      {
        id: '4',
        type: 'blood',
      },
      {
        id: '5',
        type: 'blood',
      },
    ],
    children: [
      {
        id: '11',
        type: 'blood',
      },
      {
        id: '12',
        type: 'blood',
      },
      {
        id: '13',
        type: 'blood',
      },
      {
        id: '14',
        type: 'blood',
      },
      {
        id: '15',
        type: 'blood',
      },
    ],
    siblings: [],
    spouses: [
      {
        id: '7',
        type: 'married',
      },
    ],
  },
  {
    id: '7',
    gender: 'female',
    name: 'Hidije',
    parents: [],
    children: [
      {
        id: '11',
        type: 'blood',
      },
      {
        id: '12',
        type: 'blood',
      },
      {
        id: '13',
        type: 'blood',
      },
      {
        id: '14',
        type: 'blood',
      },
      {
        id: '15',
        type: 'blood',
      },
    ],
    siblings: [],
    spouses: [],
  },
  {
    id: '3',
    gender: 'male',
    name: 'Fatmir',
    parents: [
      {
        id: '1',
        type: 'blood',
      },
      {
        id: '2',
        type: 'blood',
      },
    ],
    children: [
      {
        id: '10',
        type: 'blood',
      },
    ],
    siblings: [],
    spouses: [
      {
        id: '9',
        type: 'blood',
      },
    ],
  },
  {
    id: '9',
    gender: 'female',
    name: 'Eralda',
    parents: [],
    children: [
      {
        id: '10',
        type: 'blood',
      },
    ],
    siblings: [],
    spouses: [],
  },
  {
    id: '10',
    gender: 'male',
    name: 'Dean',
    parents: [
      { id: '3', type: 'blood' },
      { id: '9', type: 'blood' },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '8',
    gender: 'male',
    name: 'Dionis',
    parents: [
      {
        id: '1',
        type: 'blood',
      },
      {
        id: '2',
        type: 'blood',
      },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },

  {
    id: '4',
    gender: 'male',
    name: 'Qazim',
    parents: [],
    children: [
      {
        id: '1',
        type: 'blood',
      },
      {
        id: '6',
        type: 'blood',
      },
    ],
    siblings: [],
    spouses: [
      {
        id: '5',
        type: 'married',
      },
    ],
  },
  {
    id: '5',
    gender: 'female',
    name: 'Korbe',
    parents: [],
    children: [
      {
        id: '1',
        type: 'blood',
      },
      {
        id: '6',
        type: 'blood',
      },
    ],
    siblings: [],
    spouses: [],
  },
  {
    id: '11',
    gender: 'male',
    name: 'Alfred',
    parents: [
      { id: '6', type: 'Babai' },
      { id: '7', type: 'Mamaja' },
    ],
    children: [
      {
        id: '21',
        type: 'E Bija',
      },
      {
        id: '22',
        type: 'E Bija',
      },
      {
        id: '23',
        type: 'E Bija',
      },
    ],
    siblings: [],
    spouses: [
      {
        id: '16',
        type: 'Bashkshortja',
      },
    ],
  },
  {
    id: '12',
    gender: 'female',
    name: 'Liljana',
    parents: [
      { id: '6', type: 'Babai' },
      { id: '7', type: 'Mamaja' },
    ],
    children: [
      { id: '24', type: 'blood' },
      { id: '25', type: 'blood' },
      { id: '26', type: 'blood' },
    ],
    siblings: [],
    spouses: [
      {
        id: '17',
        type: 'Bashkshorti',
      },
    ],
  },
  {
    id: '13',
    gender: 'female',
    name: 'Lindita',
    parents: [
      { id: '6', type: 'Babai' },
      { id: '7', type: 'Mamaja' },
    ],
    children: [],
    siblings: [],
    spouses: [
      {
        id: '18',
        type: 'Bashkshorti',
      },
    ],
  },
  {
    id: '14',
    gender: 'female',
    name: 'Valbona',
    parents: [
      { id: '6', type: 'Babai' },
      { id: '7', type: 'Mamaja' },
    ],
    children: [
      {
        id: '27',
        type: 'I biri',
      },
      {
        id: '28',
        type: 'I biri',
      },
    ],
    siblings: [],
    spouses: [
      {
        id: '19',
        type: 'Bashkshorti',
      },
    ],
  },
  {
    id: '15',
    gender: 'male',
    name: 'Xhevahir',
    parents: [
      { id: '6', type: 'Babai' },
      { id: '7', type: 'Mamaja' },
    ],
    children: [{ id: '29', type: 'I biri' }],
    siblings: [],
    spouses: [
      {
        id: '20',
        type: 'Bashkshortja',
      },
    ],
  },
  {
    id: '16',
    gender: 'female',
    name: 'Dylbere',
    parents: [],
    children: [
      {
        id: '21',
        type: 'E Bija',
      },
      {
        id: '22',
        type: 'E Bija',
      },
      {
        id: '23',
        type: 'E Bija',
      },
    ],
    siblings: [],
    spouses: [],
  },
  {
    id: '17',
    gender: 'male',
    name: 'Gazi',
    parents: [],
    children: [
      { id: '24', type: 'blood' },
      { id: '25', type: 'blood' },
      { id: '26', type: 'blood' },
    ],
    siblings: [],
    spouses: [
      {
        id: '12',
        type: 'Bashkshortja',
      },
    ],
  },
  {
    id: '18',
    gender: 'male',
    name: 'Beqir',
    parents: [],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '19',
    gender: 'male',
    name: 'Shkelqim',
    parents: [],
    children: [
      {
        id: '27',
        type: 'I biri',
      },
      {
        id: '28',
        type: 'I biri',
      },
    ],
    siblings: [],
    spouses: [],
  },
  {
    id: '20',
    gender: 'female',
    name: 'Nertila',
    parents: [],
    children: [{ id: '29', type: 'I biri' }],
    siblings: [],
    spouses: [],
  },
  {
    id: '21',
    gender: 'female',
    name: 'Esmeralda',
    parents: [
      {
        id: '11',
        type: 'Babai',
      },
      {
        id: '16',
        type: 'Mamaja',
      },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '22',
    gender: 'female',
    name: 'Vilma',
    parents: [
      {
        id: '11',
        type: 'Babai',
      },
      {
        id: '16',
        type: 'Mamaja',
      },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '23',
    gender: 'female',
    name: 'Erisa',
    parents: [
      {
        id: '11',
        type: 'Babai',
      },
      {
        id: '16',
        type: 'Mamaja',
      },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '24',
    gender: 'male',
    name: 'Gligers',
    parents: [
      {
        id: '17',
        type: 'Babai',
      },
      {
        id: '12',
        type: 'Mamaja',
      },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '25',
    gender: 'male',
    name: 'Adriano',
    parents: [
      {
        id: '17',
        type: 'Babai',
      },
      {
        id: '12',
        type: 'Mamaja',
      },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '26',
    gender: 'male',
    name: 'Fjordi',
    parents: [
      {
        id: '17',
        type: 'Babai',
      },
      {
        id: '12',
        type: 'Mamaja',
      },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '27',
    gender: 'male',
    name: 'Niki',
    parents: [
      {
        id: '19',
        type: 'Babai',
      },
      {
        id: '14',
        type: 'Mamaja',
      },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '28',
    gender: 'male',
    name: 'Marvi',
    parents: [
      {
        id: '19',
        type: 'Babai',
      },
      {
        id: '14',
        type: 'Mamaja',
      },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
  {
    id: '29',
    gender: 'male',
    name: 'Fabio',
    parents: [
      {
        id: '15',
        type: 'Babai',
      },
      {
        id: '20',
        type: 'Mamaja',
      },
    ],
    children: [],
    siblings: [],
    spouses: [],
  },
];
